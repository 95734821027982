import React, { Component } from 'react';
import isString from 'lodash/isString';
import Span from '../natives/Span';

class Price extends Component {
  static toString(money, fixed = 2) {
    return (
      Price.literalCurrencyToSymbol(money.currency)
      + parseFloat(money.value)
        .toFixed(fixed)
    );
  }

  static literalCurrencyToSymbol(literalCurrency) {
    switch (literalCurrency) {
      case 'EUR':
        return '€\u00a0';
      case 'GBP':
        return '£\u00a0';
      case 'USD':
      case 'MXN':
        return '$\u00a0';
      case 'DKK':
        return 'kr.\u00a0';
      case 'PLN':
        return 'zl\u00a0';
      case 'SEK':
        return 'kr\u00a0';
      case 'HUF':
        return 'Ft\u00a0';
      case 'CZK':
        return 'Kč\u00a0';
      default:
        return '';
    }
  }

  get color() {
    const { color } = this.props;
    if (isString(color)) return color;
    if (this.isPositive && color) return 'green';
    if (this.isNegative && color) return 'red';
    return '';
  }

  get currency() {
    const { value } = this.props;
    return Price.literalCurrencyToSymbol(value.currency);
  }

  get inject() {
    const { id } = this.props;
    if (isString(id)) return [this.value];
    return [];
  }

  get isNegative() {
    const {
      sign,
      value
    } = this.props;
    return isString(sign) ? sign === 'negative' : value.value < 0;
  }

  get isPositive() {
    const {
      sign,
      value
    } = this.props;
    return isString(sign) ? sign === 'positive' : value.value > 0;
  }

  get symbol() {
    const { symbol } = this.props;
    if (this.isPositive && symbol) return '+\u00a0';
    if (this.isNegative && symbol) return '-\u00a0';
    return '';
  }

  get value() {
    const { value } = this.props;
    return `${this.symbol}${this.currency}${parseFloat(value.value)
      .toFixed(
        2
      )}`;
  }

  render() {
    const {
      style,
      ...props
    } = this.props;
    return (
      <Span
        {...props}
        value={this.value}
        inject={this.inject}
        style={`${this.color} ${style}`}
      />
    );
  }
}

export default Price;
